<template>
  <div class="registration-step-1-block">
    <div class="page-title"><h2>{{ $lang.app.application_check_school }}</h2></div>
    <div v-if="!results" class="check-block-container">
      <h4 class="hint-title">{{ $lang.app.application_check_instruction }}</h4>
      <div class="reg-step-form">
        <Form @submit="submit" :validation-schema="schema">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.child_pin }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="pin" type="text" autocomplete="off"
                     v-maska="'##############'"
                     v-model="checkForm.pin"/>
              <div class="tooltip-target">
                <i class="bi-question-circle"></i>
                <div class="tooltip-content tooltip-content-small">
                  <p>{{ $lang.app.birth_certificate_des }}</p>
                  <p>{{ $lang.app.birth_certificate_example }}</p>
                  <div class="passport-images">
                    <div class="passport-image">
                      <img src="../../assets/images/birth-cert.png" alt="">
                      <span>{{ $lang.app.birth_certificate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage name="pin" class="recovery-label-error" />
          </div>
          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.check }}</button>
          </div>
        </Form>
      </div>
    </div>

    <div v-if="results" class="check-results-block-container">
      <SchoolApplicationStatusResult :results="results"/>
      <div class="check-results-btn"><a href @click.prevent="results = null">{{ $lang.app.back }}</a></div>
    </div>

  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from "yup";
import SchoolApplicationStatusResult from "../../components/application-status/SchoolApplicationStatusResult";

export default {
  name: 'ApplicationCheck',
  components: {
    Field,
    Form,
    ErrorMessage,
    SchoolApplicationStatusResult
  },
  directives: { maska },
  data() {
    return {
      checkForm: {
        pin: '',
        recaptchaToken: '',
      },
      schema: yup.object({
        pin: yup.string().required(),
      }),
      results: null,
    }
  },
  methods: {
    async submit(values, actions) {
      this.$store.commit('startLoading')

      await this.$recaptchaLoaded()
      this.checkForm.recaptchaToken = await this.$recaptcha('login')

      this.$axios.post(`/application/check-status`, this.checkForm).then(({data}) => {
        this.results = data
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
        }
      }).finally(() => {
        this.$store.commit('stopLoading');
      })
    }
  }
}
</script>
<style scoped>
@import '../../assets/styles/form.css';
@import '../../assets/styles/title.css';
.check-block-container {
  width: 40%;
  margin: 0 auto;
  padding: 55px 0 45px;
  border-bottom: 1px solid #d0d0d0;
}
@media screen and (max-width: 1600px) {
  .check-block-container {
    width: 50%;
  }
}
@media screen and (max-width: 1200px) {
  .check-block-container {
    width: 60%;
    padding: 40px 0 50px;
  }
}
@media screen and (max-width: 767px) {
  .check-block-container {
    width: calc(100% - 30px);
    padding: 30px 0 35px;
  }
}

.check-results-btn {
  padding: 40px 0;
  display: flex;
  justify-content: center;
}
.check-results-btn a {
  width: 16.6666%;
  display: inline-block;
  height: 55px;
  line-height: 55px;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  padding: 0;
  transition: 0.5s;
  text-decoration: none;
  font-size: 18px;
  background: linear-gradient(to bottom, #efefef 0%, #e2e2e2 100%);
  border: 1px solid #d0d0d0;
  color: #4c9ac7;
}
@media screen and (max-width: 1600px) {
  .check-results-btn a {
    width: 25%;
  }
}
@media screen and (max-width: 1200px) {
  .check-results-btn a {
    width: 33.3333%;
  }
}
@media screen and (max-width: 767px) {
  .check-results-btn {
    padding: 30px 15px;
  }
  .check-results-btn a {
    width: 100%;
  }
}
</style>